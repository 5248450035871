import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';
import { DepartmentValidator } from 'src/core/validators/shared/department.validator';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { CategoryExceptionDto } from 'src/core/models/query/category-exception.dto';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { DepartmentSelectorComponent } from 'src/ui/department-selector/components/department-selector/department-selector.component';

@Component({
  selector: 'ca-user-defined-category-settings',
  templateUrl: './user-defined-category-settings.component.html',
  styleUrls: ['./user-defined-category-settings.component.scss'],
})
export class UserDefinedCategorySettingsComponent implements OnInit {
  @ViewChild('depSel')
  depSel: DepartmentSelectorComponent;

  userDefinedCategorySettingsForm: FormGroup;
  userDefinedCategorySettingDtos: ConfigurationSettingsDto[] = [];
  defaultWordCount: number;
  exceptions: CategoryExceptionDto[] = [];
  disabledDepartmentSelectionMessage = this.localizationService.instant(
    'ScreenRecorderManagement::DisabledDepartmentSelectionMessage'
  );

  private readonly settingKeyAgentBeginningWordCount =
    'UserDefinedCategory.AgentBeginningWordCount';
  private readonly settingKeyAgentEndingWordCount = 'UserDefinedCategory.AgentEndingWordCount';
  private readonly settingKeyCustomerBeginningWordCount =
    'UserDefinedCategory.CustomerBeginningWordCount';
  private readonly settingKeyCustomerEndingWordCount =
    'UserDefinedCategory.CustomerEndingWordCount';
  private readonly settingKeySeperateValueForChannelsEnabled =
    'UserDefinedCategory.SeperateValueForChannelsEnabled';
  private readonly settingKeyEnableIdentifyDepartmentException =
    'UserDefinedCategory.EnableIdentifyDepartmentException';

  get sameValueForChannels(): boolean {
    return !this.userDefinedCategorySettingsForm.get('seperateValueForChannelsEnabled').value;
  }

  departmentIdInUseList(ex, event): [] {
    let ids: any[] = [];
    const currentDepartments = ex?.value.departments?.map(m =>
      m.department != null ? m.department.id : m.id
    ) as [];
    let selectedDepartments = this.userDefinedCategorySettingsForm
      .get('exceptions')
      ?.value.map(({ departments }) => departments);

    if (selectedDepartments.length > 0) {
      selectedDepartments = selectedDepartments.filter(x => x != null);
      for (let i = 0; i < selectedDepartments.length; i++) {
        const departments = selectedDepartments[i];

        if (departments) {
          for (let k = 0; k < departments.length; k++) {
            const department = departments[k];

            if (!currentDepartments) {
              ids.push(department.id);
            } else {
              if (currentDepartments.findIndex(d => d == department.id) == -1) {
                ids.push(department.id);
              }
            }
          }
        }
      }
    }

    return ids as [];
  }

  private addCategorySettings() {
    if (this.sameValueForChannels) {
      this.userDefinedCategorySettingsForm
        .get('customerBeginningWordCount')
        .setValue(this.userDefinedCategorySettingsForm.get('agentBeginningWordCount').value);
      this.userDefinedCategorySettingsForm
        .get('customerEndingWordCount')
        .setValue(this.userDefinedCategorySettingsForm.get('agentEndingWordCount').value);
    }
    this.userDefinedCategorySettingDtos.push(
      {
        settingName: this.settingKeyAgentBeginningWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('agentBeginningWordCount')
          .value.toString(),
      },
      {
        settingName: this.settingKeyAgentEndingWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('agentEndingWordCount')
          .value.toString(),
      },
      {
        settingName: this.settingKeyCustomerBeginningWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('customerBeginningWordCount')
          .value.toString(),
      },
      {
        settingName: this.settingKeyCustomerEndingWordCount,
        settingValue: this.userDefinedCategorySettingsForm
          .get('customerEndingWordCount')
          .value.toString(),
      },
      {
        settingName: this.settingKeySeperateValueForChannelsEnabled,
        settingValue: this.userDefinedCategorySettingsForm
          .get('seperateValueForChannelsEnabled')
          .value.toString(),
      },
      {
        settingName: this.settingKeyEnableIdentifyDepartmentException,
        settingValue: this.userDefinedCategorySettingsForm
          .get('enableIdentifyDepartmentException')
          .value.toString(),
      }
    );
  }

  onSubmitCategorySettings() {
    this.confirmationService
      .warn('Settings::IntervalCategorySettingConfirmationMessage', 'Settings::SaveChanges', {
        yesText: '::Save',
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          if (this.userDefinedCategorySettingsForm.invalid) {
            return;
          }

          this.addCategorySettings();

          let exceptions = this.userDefinedCategorySettingsForm.get('exceptions') as FormArray;

          let categoryExceptions: CategoryExceptionDto[] = [];

          for (let i = 0; i < exceptions.length; i++) {
            let exception = exceptions.at(i).value;

            exception.id = exception.id == null ? 0 : exception.id;

            let departmensValue: DepartmentDto[] = [];

            exception.departments.forEach(f => {
              if (f.department != null) {
                departmensValue.push(f.department);
                return;
              }

              departmensValue.push(f);
            });

            categoryExceptions.push({
              agentBeginningWordCount: exception.agentBeginningWordCount,
              agentEndingWordCount: exception.agentEndingWordCount,
              customerBeginningWordCount: exception.customerBeginningWordCount,
              customerEndingWordCount: exception.customerEndingWordCount,
              departments: departmensValue,
              id: exception.id,
              seperateValueForChannelsEnabled: exception.seperateValueForChannelsEnabled,
            });
          }

          combineLatest([
            this.settingService.saveSetting(this.userDefinedCategorySettingDtos),
            this.settingService.saveCategoryException(categoryExceptions),
          ]).subscribe({
            next: () => {
              this.toastr.success(
                this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
              );
            },
          });
        }
      });
  }

  onSeperateValueChange(eventArgs) {
    if (eventArgs.target.checked) {
      this.userDefinedCategorySettingsForm
        .get('agentBeginningWordCount')
        .setValue(this.defaultWordCount);
      this.userDefinedCategorySettingsForm
        .get('agentEndingWordCount')
        .setValue(this.defaultWordCount);
      this.userDefinedCategorySettingsForm
        .get('customerBeginningWordCount')
        .setValue(this.defaultWordCount);
      this.userDefinedCategorySettingsForm
        .get('customerEndingWordCount')
        .setValue(this.defaultWordCount);
    }
  }

  popoverClicked(popover: NgbPopover, event: any): boolean {
    popover.toggle();
    event.stopPropagation();
    return false;
  }

  addException(exception?: CategoryExceptionDto) {
    const exceptions = this.userDefinedCategorySettingsForm.get('exceptions') as FormArray;

    if (exception) {
      exceptions.push(
        this.fb.group({
          id: exception.id,
          intervalId: [exceptions.length],
          seperateValueForChannelsEnabled: [exception.seperateValueForChannelsEnabled],
          agentBeginningWordCount: [
            exception.agentBeginningWordCount,
            { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
          ],
          agentEndingWordCount: [
            exception.agentEndingWordCount,
            { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
          ],
          customerBeginningWordCount: [
            exception.customerBeginningWordCount,
            { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
          ],
          customerEndingWordCount: [
            exception.customerEndingWordCount,
            { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
          ],
          departments: [exception.departments, { validators: [Validators.required] }],
        })
      );

      return;
    }

    exceptions.push(
      this.fb.group({
        id: [null],
        intervalId: [exceptions.length],
        seperateValueForChannelsEnabled: [false],
        agentBeginningWordCount: [
          this.defaultWordCount,
          { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
        ],
        agentEndingWordCount: [
          this.defaultWordCount,
          { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
        ],
        customerBeginningWordCount: [
          this.defaultWordCount,
          { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
        ],
        customerEndingWordCount: [
          this.defaultWordCount,
          { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
        ],
        departments: [null, { validators: [Validators.required] }],
      })
    );
  }

  onClickDeleteException(id) {
    const exceptions = this.userDefinedCategorySettingsForm.get('exceptions') as FormArray;

    exceptions.removeAt(id);
  }

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private confirmationService: ConfirmationService,
    private globalSettingService: GlobalSettingsService
  ) {
    this.userDefinedCategorySettingsForm = fb.group({
      agentBeginningWordCount: [
        this.config.getSetting(this.settingKeyAgentBeginningWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
      agentEndingWordCount: [
        this.config.getSetting(this.settingKeyAgentEndingWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
      customerBeginningWordCount: [
        this.config.getSetting(this.settingKeyCustomerBeginningWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
      customerEndingWordCount: [
        this.config.getSetting(this.settingKeyCustomerEndingWordCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, 9999)] },
      ],
      seperateValueForChannelsEnabled: [
        JSON.parse(
          this.config.getSetting(this.settingKeySeperateValueForChannelsEnabled).toLowerCase()
        ),
      ],
      enableIdentifyDepartmentException: [
        JSON.parse(
          this.config.getSetting(this.settingKeyEnableIdentifyDepartmentException).toLowerCase()
        ),
      ],
      exceptions: fb.array([]),
    });

    this.settingService.getCategoryExceptions().subscribe(data => {
      this.exceptions = data;

      data.forEach(exception => {
        this.addException(exception);
      });
    });

    this.defaultWordCount = this.globalSettingService.systemLanguageCode === 'en-US' ? 700 : 350;
  }

  ngOnInit(): void {}
}
