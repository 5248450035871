<div>
  <h2>{{ 'Settings::Redaction' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="settingsForm" (ngSubmit)="onSubmitSettings()">
  <div class="form-group">
    <input
      type="checkbox"
      id="dynamicDataMaskingEnabled"
      formControlName="dynamicDataMaskingEnabled"
      (change)="onDynamicDataMaskingEnabledChanged($event)" />
    <label class="ps-1" for="dynamicDataMaskingEnabled">
      {{ 'Settings::RedactionEnabled' | abpLocalization }}
    </label>
  </div>
  <small class="form-text text-muted info-margin">
    {{ 'Settings::RedactionEnabledInfo' | abpLocalization }}
  </small>
  <div *ngIf="settingsForm.get('dynamicDataMaskingEnabled').value">
    <div class="form-group pt-2 selector-class">
      <label for="entities">
        {{ getSelectedCountText() }}
      </label>
      <div *ngIf="namedEntityList?.length">
        <div class="entity-container">
          <div *ngFor="let entity of namedEntityList; let i = index" class="entity-column">
            <div class="entity-column-label" [disabled]="entity.disabled">
              <label>
                <input
                  type="checkbox"
                  [checked]="isSelected(entity)"
                  [disabled]="isDisabled(entity)"
                  (change)="onEntityChange($event, entity)" />
                {{ 'Conversation::NamedEntity:' + entity.displayName | abpLocalization }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group pt-2">
      <label for="redactionMode">
        {{ 'Settings::RedactionMode' | abpLocalization }}
      </label>
      <div *ngFor="let mode of redactionModes">
        <div class="display-inline">
          <input
            id="radio{{ mode.id }}"
            type="radio"
            class="radio-button"
            formControlName="redactionMode"
            [value]="mode.code" />
          <label for="radio{{ mode.id }}" class="ps-1">
            {{ 'GenericLookup::' + mode.code | abpLocalization }}
          </label>
        </div>
        <small class="form-text text-muted info-margin">
          {{ 'Settings::' + mode.name + 'Info' | abpLocalization }}
        </small>
      </div>
    </div>
  </div>
  <div>
    <button type="submit" class="btn btn-primary mt-3 mb-3">
      <i class="me-1 fas fa-save"></i>
      {{ 'AbpUi::Save' | abpLocalization }}
    </button>
  </div>
</form>
