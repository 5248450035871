import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { ScenarioDto } from 'src/core/models/real-time/scenario.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { ScenarioSelectionConfigurationModel } from '../models/scenario-selection-configuration.model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

@Component({
  selector: 'ca-scenario-selection-panel',
  templateUrl: './scenario-selection-panel.component.html',
  styleUrls: ['./scenario-selection-panel.component.scss'],
})
export class ScenarioSelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  actualConfig: ScenarioSelectionConfigurationModel;

  _value: ScenarioDto[];
  scenarioSelectorOptions: any;

  @ViewChild(SelectorComponent, { read: SelectorComponent })
  scenarioSelector: SelectorComponent;
  filters: FilterItemDto[] = [];

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as ScenarioSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(
    private localizationService: LocalizationService,
    private operators: Operators,
    protected fb: FormBuilder
  ) {
    super(fb);

    this.scenarioSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('::SelectScenario'),
      multiple: true,
      url: 'api/app/scenario/simple-scenario',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
      filters: this.filters,
    };

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  autoSelectFirstItem(): void {}

  ngOnInit(): void {}

  reload(): void {}

  reset(): void {}

  init() {
    super.init();
    if (this.actualConfig.autoLoad) {
      this.scenarioSelector.refresh();
    }
  }
}
