import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs';
import { NamedEntityDto } from 'src/core/models/conversation/named-entity-recognition/named-entity.dto';
import { ConversationNamedEntityType } from 'src/core/models/generic-lookup-type/conversation/named-entity-type.glt.';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { NamedEntitySettingsService } from 'src/core/services/settings/named-entity-settings.service';

@Component({
  selector: 'ca-named-entity-recognition-settings',
  templateUrl: './named-entity-recognition-settings.component.html',
  styleUrls: ['./named-entity-recognition-settings.component.scss']
})
export class NamedEntityRecognitionSettingsComponent implements OnInit {
  form: FormGroup;
  definitionList: NamedEntityDto[] = [];

  constructor(
    private fb: FormBuilder,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private namedEntitySettingsService: NamedEntitySettingsService,
    private operators: Operators,
  ) {
    this.form = fb.group({
      definitions: [],
    });
    let filters: FilterItemDto[] = [];
    filters.push({
      field: 'isActive',
      operator: this.operators.Equals,
      value: true,
    });
    filters.push({
      field: 'onlyNEREntities',
      operator: this.operators.Equals,
      value: true,
    });
    let sorters: SorterItemDto[] = [{
      field: 'displayName',
      direction: 'ASC',
    }];
    this.namedEntitySettingsService.getNEREntities(filters, sorters)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.definitionList = response.items;
          this.definitionList = this.definitionList.map((p) =>
            p.namedEntityDetails.filter(y => y.typeId == ConversationNamedEntityType.redaction && y.isEnabled).length > 0
              ? { ...p, disabled: true } : p
          );
          const enabledEntities = this.definitionList.filter(x => x.namedEntityDetails
            .filter(y => y.typeId == ConversationNamedEntityType.NER && y.isEnabled).length > 0);
          this.form.get('definitions').setValue(enabledEntities.map(x => x.id));
        },
        error: () => { },
      });
  }

  ngOnInit(): void { }

  onSubmitForm() {
    if (!this.form.valid) {
      return;
    }

    let definitions = this.form.get('definitions').value ?? [];
    definitions = this.definitionList.filter(x => definitions.includes(x.id) && x.namedEntityDetails.filter(y => y.typeId == ConversationNamedEntityType.redaction && y.isEnabled).length == 0).map(x => x.id);
    this.namedEntitySettingsService.updateNEREnability(definitions).subscribe(response => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });;
  }

  removeFromSelected(id) {
    let selectedItems = this.form.get('definitions').value ?? [];
    if (selectedItems) {
      const indexToRemoveFromSelected = selectedItems.findIndex(idx => idx === id);
      if (indexToRemoveFromSelected > -1) {
        selectedItems.splice(indexToRemoveFromSelected, 1);
        this.form.get('definitions').setValue(selectedItems);
      }
    }
  }
}
