import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { map, take, takeUntil } from 'rxjs/operators';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConversationRedactionMode } from 'src/core/models/generic-lookup-type/conversation/redaction-mode.glt';
import { NamedEntitySettingsService } from 'src/core/services/settings/named-entity-settings.service';
import { NamedEntityDto } from 'src/core/models/conversation/named-entity-recognition/named-entity.dto';
import { ConversationNamedEntityType } from 'src/core/models/generic-lookup-type/conversation/named-entity-type.glt.';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { QueryService } from 'src/core/services/query/query.service';

@Component({
  selector: 'ca-redaction-settings',
  templateUrl: './redaction-settings.component.html',
  styleUrls: ['./redaction-settings.component.scss'],
})
export class RedactionSettingsComponent extends HasSubscription implements OnInit {
  settingsForm: FormGroup;
  redactionSettingDtos: ConfigurationSettingsDto[] = [];
  redactionModes: GenericLookupDto[];
  defaultRedactionModeCode: string;
  namedEntityList: NamedEntityDto[] = [];
  selectedEntities: NamedEntityDto[] = [];
  disabledEntities: NamedEntityDto[] = [];
  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];

  private readonly settingKeyDynamicDataMaskingEnabled = 'Global.DynamicDataMaskingEnabled';
  private readonly settingKeyRedactionMode = 'Redaction.RedactionMode';

  constructor(
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private store: Store,
    private config: ConfigStateService,
    private fb: FormBuilder,
    private namedEntitySettingsService: NamedEntitySettingsService,
    private operators: Operators,
    private globalSettingsService: GlobalSettingsService,
    private queryService: QueryService
  ) {
    super();
    let filters: FilterItemDto[] = [];
    filters.push({
      field: 'isActive',
      operator: this.operators.Equals,
      value: true,
    });
    this.sorters.push({
      field: 'displayName',
      direction: 'ASC',
    });
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .pipe(map(filterFn => filterFn(ConversationRedactionMode)))
      .subscribe(result => {
        this.redactionModes = result;
        this.defaultRedactionModeCode = this.redactionModes.find(
          f => f.id === ConversationRedactionMode.replaceWithCharacter
        ).code;
      });
    let redactionModeCode =
      this.config.getSetting(this.settingKeyRedactionMode) ?? this.defaultRedactionModeCode;
    let dynamicDataMaskingEnabled = this.globalSettingsService.dynamicDataMaskingEnabled;

    this.settingsForm = fb.group({
      dynamicDataMaskingEnabled: [dynamicDataMaskingEnabled],
      redactionMode: [redactionModeCode],
    });

    if (dynamicDataMaskingEnabled) {
      this.setRedactionEntities();
    }
  }

  ngOnInit(): void {}

  getSelectedCountText(): string {
    let selectionText = this.localizationService.instant(
      'Settings::NamedEntityRecognitionEntities'
    );
    selectionText = selectionText.replace('{0}', this.selectedEntities?.length.toString());
    selectionText = selectionText.replace('{1}', this.namedEntityList.length.toString());
    return selectionText;
  }

  setRedactionEntities() {
    this.namedEntitySettingsService
      .getRedactionEntities(this.filters, this.sorters)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.namedEntityList = response.items;
          this.selectedEntities = this.namedEntityList.filter(
            x =>
              x.namedEntityDetails.filter(
                y => y.typeId == ConversationNamedEntityType.redaction && y.isEnabled
              ).length > 0
          );
          if (this.namedEntityList.length == 0) {
            this.toastr.info(this.localizationService.instant('Settings::NoRedactionEntity'));
          }
        },
        error: () => {},
      });
  }

  onEntityChange(event, entity: NamedEntityDto) {
    if (event.target.checked) {
      this.selectedEntities.push(entity);
    } else {
      this.selectedEntities = this.selectedEntities.filter(
        x => x.displayName != entity.displayName
      );
    }
  }

  onDynamicDataMaskingEnabledChanged(eventArgs) {
    if (eventArgs.target.checked) {
      this.queryService.getExistCallerOrCalledNumber().subscribe(response => {
        if (response === true) {
          this.settingsForm.get('dynamicDataMaskingEnabled').setValue(false);
          this.settingsForm.get('redactionMode').setValue(this.defaultRedactionModeCode);
          this.settingsForm.get('redactionMode').clearValidators();
          return;
        }

        this.setRedactionEntities();
        this.settingsForm.get('redactionMode').setValidators(Validators.required);
        this.settingsForm.get('redactionMode').setValue(this.defaultRedactionModeCode);
        this.settingsForm.get('redactionMode').updateValueAndValidity();
      });
    } else {
      this.settingsForm.get('redactionMode').setValue(this.defaultRedactionModeCode);
      this.settingsForm.get('redactionMode').clearValidators();
    }
  }

  onSubmitSettings() {
    if (this.settingsForm.invalid) {
      return;
    }
    this.saveSettings();
  }

  saveSettings() {
    if (this.settingsForm.invalid) {
      return;
    }

    this.addRedactionSettings();
    let entities = this.selectedEntities.filter(
      x => this.disabledEntities.filter(y => y.displayName == x.displayName).length == 0
    );
    this.namedEntitySettingsService.updateRedactionEnability(entities).subscribe(response => {
      this.settingService
        .saveSetting(this.redactionSettingDtos)
        .pipe(take(1))
        .subscribe(res => {
          this.toastr.success(
            this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
          );
        });
    });
  }

  isSelected(entity: NamedEntityDto) {
    return this.selectedEntities.filter(x => x.displayName == entity.displayName).length > 0;
  }

  isDisabled(entity: NamedEntityDto) {
    return this.disabledEntities.filter(x => x.displayName == entity.displayName).length > 0;
  }

  private addRedactionSettings() {
    this.redactionSettingDtos = new Array();
    this.redactionSettingDtos.push({
      settingName: this.settingKeyDynamicDataMaskingEnabled,
      settingValue: this.settingsForm.get('dynamicDataMaskingEnabled').value.toString(),
    });

    if (this.settingsForm.get('dynamicDataMaskingEnabled').value) {
      this.redactionSettingDtos.push({
        settingName: this.settingKeyRedactionMode,
        settingValue: this.settingsForm.get('redactionMode').value.toString(),
      });
    }
  }
}
