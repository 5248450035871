<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ '(' + ('Conversation::QueryBuilder:GenAI' | abpLocalization) + ')' }}</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ 'Conversation::QueryBuilder:Prompt' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>"{{ payload.genAIPrompt }}"</label>
      </div>
      <div class="col-auto" *ngIf="currentGenAiYesNoQueryType === genAiYesNoQueryType.Interval">
        <label>{{ 'Conversation::QueryBuilder:Interval' | abpLocalization }}:&nbsp;</label>
      </div>
      <div
        class="col-auto"
        *ngIf="currentGenAiYesNoQueryType === genAiYesNoQueryType.Interval"
        container="body"
        placement="bottom"
        ngbTooltip=" {{
          currentGenAiYesNoQueryIntervalType === genAiYesNoQueryIntervalType.Opening
            ? getOpeningIntervalTooltip()
            : getClosingIntervalTooltip()
        }}">
        <label *ngIf="currentGenAiYesNoQueryIntervalType === genAiYesNoQueryIntervalType.Opening">
          {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
        </label>
        <label *ngIf="currentGenAiYesNoQueryIntervalType === genAiYesNoQueryIntervalType.Closing">
          {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <div ngbDropdown class="d-inline-block">
          <button ngbDropdownToggle class="btn btn-sm ca-btn ca-btn-neutral-command">
            <i class="far fa-bars"></i>
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(genAiYesNoQueryType.Basic)">
              <i class="fas fa-circle small-icon"></i>
              <span>{{ 'Conversation::QueryBuilder:GenAIYesNo' | abpLocalization }}</span>
            </button>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(genAiYesNoQueryType.Interval)">
              <i class="fas fa-circle small-icon"></i>
              <span>{{ 'Conversation::QueryBuilder:GenAIYesNoWithRange' | abpLocalization }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <label>{{ 'Conversation::QueryBuilder:GenAIYesNo' | abpLocalization }}</label>
      </div>
      <div class="col-auto flex-auto position-relative">
        <ca-template-dropdown-selector
          #inputPrompt
          *ngIf="!expanded"
          (focus)="checkAndExpandPromptInputIfNeeded(inputPrompt)"
          (input)="checkAndExpandPromptInputIfNeeded(inputPrompt)"
          [input$]="queryService.getGenAIPromptTemplates()"
          [title]="'title'"
          [template]="'prompt'"
          [list]="'templates'"
          [(ngModel)]="payload.genAIPrompt"
          [placeholder]="'Conversation::QueryBuilder:Prompt.Placeholder' | abpLocalization"
          [class]="'form-control form-control-sm truncate-text'"
          class="w-100 absolute-location position-absolute"
          formControlName="genAIPrompt"
          ngbTooltip="{{ 'Conversation::QueryBuilder:Prompt' | abpLocalization }}"
          container="body"
          placement="bottom"></ca-template-dropdown-selector>
        <textarea
          *ngIf="expanded"
          (blur)="expanded = false"
          type="text"
          [(ngModel)]="payload.genAIPrompt"
          class="form-control form-control-sm position-absolute"
          rows="5"
          formControlName="genAIPrompt"
          ngbTooltip="{{ 'Conversation::QueryBuilder:Prompt' | abpLocalization }}"
          container="body"
          placement="bottom"
          autofocus></textarea>
      </div>
      <div class="col-auto radio-container" container="body" placement="bottom">
        <div container="body" placement="top auto">
          <input
            type="radio"
            class="radio-input"
            attr.id="radioYes"
            [value]="true"
            formControlName="response" />
          <label [attr.for]="'radioYes'" class="radio-label">
            {{ '::Yes' | abpLocalization }}
          </label>
        </div>
        <div style="margin-left: 12px" container="body" placement="top auto">
          <input
            type="radio"
            class="radio-input"
            attr.id="radioNo"
            [value]="false"
            formControlName="response" />
          <label [attr.for]="'radioNo'" class="radio-label">
            {{ '::No' | abpLocalization }}
          </label>
        </div>
      </div>
      <div class="col-auto">
        <ca-conversation-side-selector
          formControlName="conversationSide"
          [allSide]="true"
          [anySide]="false"></ca-conversation-side-selector>
      </div>
      <div
        class="col-auto radio-container"
        container="body"
        placement="bottom"
        *ngIf="currentGenAiYesNoQueryType === genAiYesNoQueryType.Interval">
        <div ngbTooltip="{{ getOpeningIntervalTooltip() }}" container="body" placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioOpening_' + payload.internalId"
            #radioOpening
            value="{{ genAiYesNoQueryIntervalType.Opening }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioOpening_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
          </label>
        </div>
        <div
          style="margin-left: 12px"
          ngbTooltip="{{ getClosingIntervalTooltip() }}"
          container="body"
          placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioClosing_' + payload.internalId"
            #radioClosing
            value="{{ genAiYesNoQueryIntervalType.Closing }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioClosing_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
          </label>
        </div>
      </div>
      <div style="gap: 8px" class="col-auto d-flex flex-row justify-content-center">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          (click)="onClickDuplicate($event)"
          ngbTooltip="{{ '::Duplicate' | abpLocalization }} (Shift + Enter)"
          container="body"
          placement="left">
          <i class="far fa-copy"></i>
        </button>
        <ca-query-builder-node-remove-button
          (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn ca-btn ca-btn-neutral" (click)="test()">
          <i *ngIf="testing" class="fas fa-spinner fa-spin mb-1"></i>
          <i *ngIf="!testing" class="far fa-bullseye mb-1"></i>
          {{ 'Conversation::QueryBuilder:GenAI.Test' | abpLocalization }}
        </button>
      </div>
      <div class="col-auto test-response-button">
        <button
          class="btn ca-btn ca-btn-neutral"
          #popover="ngbPopover"
          [ngbPopover]="popoverTestResponse"
          popoverClass="gen-ai-test-response-popover"
          placement="auto"
          triggers="manual"
          container="body"
          [autoClose]="'outside'"
          (click)="toggleTestResponsePopover(popover, $event)"
          [hidden]="data.length <= 0">
          {{ 'Conversation::QueryBuilder:GenAI.TestResponse' | abpLocalization }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #popoverTestResponse>
  <div>
    <table mat-table [dataSource]="dataSource" style="width: 100%">
      <ng-container *ngFor="let gridColumn of gridColumns">
        <ng-container
          *ngIf="gridColumn.columnName !== 'conversationId'"
          matColumnDef="{{ gridColumn.columnName }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="gridColumn.width"
            [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width">
            {{ gridColumn.header }}
          </th>
          <td
            mat-cell
            *matCellDef="let conversation"
            [innerHtml]="conversation | objectparser : gridColumn"></td>
        </ng-container>
        <ng-container
          *ngIf="gridColumn.columnName === 'conversationId'"
          matColumnDef="{{ gridColumn.columnName }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="gridColumn.width"
            [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width"
            [ngClass]="gridColumn.headerClass">
            {{ gridColumn.header }}
          </th>
          <td mat-cell [ngClass]="gridColumn.cellClass" *matCellDef="let response" container="body">
            <ca-clickable-link
              [innerHtml]="response | objectparser : gridColumn"
              [targetLink]="['/conversation', 'v2', response.conversationId]"
              [newTab]="true"
              [showTooltipIfTruncated]="true"></ca-clickable-link>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        class="conversation-row-{{ myRowData.id }}"
        *matRowDef="let myRowData; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-template>
