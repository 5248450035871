import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { GenerativeQAService } from 'src/core/models/generic-lookup-type/generative-qna/services.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { Store } from '@ngxs/store';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs';

@Component({
  selector: 'ca-generative-qa-settings',
  templateUrl: './generative-qa-settings.component.html',
  styleUrls: ['./generative-qa-settings.component.scss'],
})
export class GenerativeQASettingsComponent implements OnInit {
  form: FormGroup;
  generativeQAFeatureEnabled = false;
  VAEnabled = false;
  serviceTypes: GenericLookupDto[];
  serviceValue: string;
  projectValue: string;

  private readonly prefix = 'GenerativeQA';
  private readonly project = this.prefix + '.Project';
  private readonly service = this.prefix + '.Service';
  private readonly endpoint = this.prefix + '.Endpoint';
  private readonly identityBaseEndpoint = this.prefix + '.IdentityBaseEndpoint';
  private readonly clientId = this.prefix + '.ClientId';
  private readonly clientSecret = this.prefix + '.ClientSecret';
  private readonly promptMessage = this.prefix + '.PromptMessage';

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private featureService: FeatureService,
    private store: Store
  ) {
    this.generativeQAFeatureEnabled = this.featureService.isEnabled(FeatureConstants.GenerativeQA);
    this.VAEnabled = this.featureService.isEnabled(FeatureConstants.VirtualAgentIntegration);

    this.store
    .select(GenericLookupTypeState.getGenericLookups)
    .pipe(map(filterFn => filterFn(GenerativeQAService)))
    .subscribe(result => {
      this.serviceTypes = result;

      if (!this.VAEnabled) {
        this.serviceTypes = this.serviceTypes.filter(service => service.code !== 'GenerativeQA.Service.KnovvuVA');
      }

    });

    this.serviceValue = this.config.getSetting(this.service);

    if (!this.serviceValue) {
      this.serviceValue = this.VAEnabled ? 'GenerativeQA.Service.KnovvuVA' : 'GenerativeQA.Service.KnovvuML';
    }

    if(this.serviceValue == "GenerativeQA.Service.KnovvuVA" && !this.VAEnabled) {
      this.serviceValue = 'GenerativeQA.Service.KnovvuML';
    }

    this.projectValue = this.config.getSetting(this.project);

    if (!this.projectValue) {
      this.projectValue = 'RTG Integration';
    }

    this.form = this.fb.group({
      service: [this.serviceValue, Validators.required],
      project: this.fb.group({
        name: this.project,
        value: [
          this.projectValue,
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      endpoint: this.fb.group({
        name: this.endpoint,
        value: [
          this.config.getSetting(this.endpoint),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      identityBaseEndpoint: this.fb.group({
        name: this.identityBaseEndpoint,
        value: [
          this.config.getSetting(this.identityBaseEndpoint),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      promptMessage: this.fb.group({
        name: this.promptMessage,
        value: [
          this.config.getSetting(this.promptMessage),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      })
    });
  }

  isServiceSelected(serviceName): boolean {
    const serviceValue = this.form.get('service').value;
    return serviceValue && serviceValue === serviceName;
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push(
      {
        settingName: this.service,
        settingValue: this.form.get('service').value,
      },
      {
        settingName: this.form.get('project').get('name').value,
        settingValue: this.form.get('project').get('value').value
      },
      {
        settingName: this.form.get('endpoint').get('name').value,
        settingValue: this.form.get('endpoint').get('value').value,
      },
      {
        settingName: this.form.get('identityBaseEndpoint').get('name').value,
        settingValue: this.form.get('identityBaseEndpoint').get('value').value,
      },
      {
        settingName: this.form.get('clientId').get('name').value,
        settingValue: this.form.get('clientId').get('value').value
      },
      {
        settingName: this.form.get('promptMessage').get('name').value,
        settingValue: this.form.get('promptMessage').get('value').value,
      }
    );

    if(this.form.get('clientSecret').get('value').value) {
      settings.push({
        settingName: this.form.get('clientSecret').get('name').value,
        settingValue: this.form.get('clientSecret').get('value').value
      })
    }

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
