import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { first, map, mergeMap } from 'rxjs/operators';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { QueryRangeUnit } from 'src/core/models/generic-lookup-type/query/query-range-unit.glt';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Operators } from 'src/core/models/request/operator.enum';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { QueryConstants } from 'src/core/constants/query-constant';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { GenAIYesNoQueryItemDto } from 'src/core/models/query/gen-ai-yesno-query-item.dto';
import { GenAIYesNoWithRangeQueryItemDto } from 'src/core/models/query/gen-ai-yesno-with-range-query-item.dto';
import { QueryService } from 'src/core/services/query/query.service';
import { GenAIQueryItemTestRequestDto } from 'src/core/models/query/gen-ai-query-item-test-request.dto';
import { QueryItemDto } from 'src/core/models/query/query-item.dto';
import { MatTableDataSource } from '@angular/material/table';
import { GenAIQueryItemTestResponseDto } from 'src/core/models/query/gen-ai-query-item-test-response.dto';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';

export enum GenAiYesNoQueryType {
  Basic = 1,
  Interval,
}

export enum GenAiYesNoQueryIntervalType {
  Opening = '1',
  Closing = '2',
}

@Component({
  selector: 'ca-query-tree-node-gen-ai-yesno',
  templateUrl: './query-tree-node-gen-ai-yesno.component.html',
  styleUrls: ['./query-tree-node-gen-ai-yesno.component.scss'],
})
export class QueryTreeNodeGenAiYesnoComponent extends QueryEditorBase implements OnInit {
  payload: any;
  conversationSides$: Observable<GenericLookupDto[]>;
  rangeUnits$: Observable<GenericLookupDto[]>;
  queryForm: FormGroup = null;
  currentConversationSide: string;
  expanded: boolean = false;
  testing: boolean = false;
  exceptList: number[] = [];
  popover: NgbPopover;

  dataSource = new MatTableDataSource<GenAIQueryItemTestResponseDto>();
  data: GenAIQueryItemTestResponseDto[] = [];
  displayedColumns: string[] = [];

  genAiYesNoQueryType = GenAiYesNoQueryType;
  genAiYesNoQueryIntervalType = GenAiYesNoQueryIntervalType;

  private currentGenAiYesNoQueryTypeInternal: GenAiYesNoQueryType = GenAiYesNoQueryType.Basic;
  private currentGenAiYesNoQueryIntervalTypeInternal = GenAiYesNoQueryIntervalType.Opening;
  readonly RANGE_SECONDS = QueryConstants.RANGE_OPENING_END_SECOND.toString();

  rangeUnitSecondsId: number;

  formOperator: number;
  formIsNotQuery: boolean;
  formSideId: number;
  formRangeUnitId: number;
  formStartTime: number;
  formEndTime: number;

  inputPhrases$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  conversationType = ConversationType;

  intervalAgentQueryFirstNWords: string = '';
  intervalAgentQueryLastNWords: string = '';
  intervalCustomerQueryFirstNWords: string = '';
  intervalCustomerQueryLastNWords: string = '';
  seperateIntervalValueForChannelsEnabled: boolean = false;

  get currentGenAiYesNoQueryType(): GenAiYesNoQueryType {
    return this.currentGenAiYesNoQueryTypeInternal;
  }

  get currentGenAiYesNoQueryIntervalType(): GenAiYesNoQueryIntervalType {
    return this.currentGenAiYesNoQueryIntervalTypeInternal;
  }

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'conversationId',
      header: this.localizationService.instant('::Id'),
      binding: 'conversationId',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      tooltip: undefined,
    },
    {
      columnName: 'response',
      header: this.localizationService.instant('Conversation::QueryBuilder:GenAI.Response'),
      binding: 'genAIResponse',
      valueParser: undefined,
      pipes: undefined,
      width: '80%',
      tooltip: undefined,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    public operators: Operators,
    private queryService: QueryService,
    private queryFieldDataType: QueryFieldDataType,
    private genericLookupService: GenericLookupTypeService,
    private localizationService: LocalizationService,
    private config: ConfigStateService
  ) {
    super();

    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }

    this.intervalAgentQueryFirstNWords = this.config.getSetting(
      'UserDefinedCategory.AgentBeginningWordCount'
    );
    this.intervalAgentQueryLastNWords = this.config.getSetting(
      'UserDefinedCategory.AgentEndingWordCount'
    );
    this.intervalCustomerQueryFirstNWords = this.config.getSetting(
      'UserDefinedCategory.CustomerBeginningWordCount'
    );
    this.intervalCustomerQueryLastNWords = this.config.getSetting(
      'UserDefinedCategory.CustomerEndingWordCount'
    );
    this.seperateIntervalValueForChannelsEnabled = JSON.parse(
      this.config.getSetting('UserDefinedCategory.SeperateValueForChannelsEnabled').toLowerCase()
    );

    this.rangeUnits$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(QueryRangeUnit)));

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.rangeUnits$
      .pipe(
        first(),
        mergeMap(x => x.filter(y => y.code == QueryConstants.RANGE_UNIT_SECONDS_CODE))
      )
      .subscribe(data => {
        this.rangeUnitSecondsId = data.id;
      });

    this.queryForm = fb.group(
      {
        genAIPrompt: [
          null,
          {
            validators: [Validators.required, Validators.minLength(1), Validators.maxLength(600)],
          },
        ],
        response: [null],
        conversationSide: [null],
        operator: [this.operators.Contains],
        intervalType: [GenAiYesNoQueryIntervalType.Opening],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('genAIPrompt').valueChanges.subscribe(value => {
      this.queryForm.updateValueAndValidity();
    });

    this.queryForm.get('response').valueChanges.subscribe(value => {
      this.payload.response = value;
    });

    this.queryForm.get('conversationSide').valueChanges.subscribe(value => {
      this.formSideId = this.queryForm.get('conversationSide').value;
      this.payload.sideId = this.formSideId;
      this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
        this.payload.sideId
      )?.code;
    });

    this.queryForm.get('intervalType').valueChanges.subscribe(value => {
      const intervalType: GenAiYesNoQueryIntervalType = this.queryForm.get('intervalType').value;
      this.formRangeUnitId = this.rangeUnitSecondsId;
      this.payload.rangeUnitId = this.formRangeUnitId;

      if (intervalType === GenAiYesNoQueryIntervalType.Opening) {
        this.formStartTime = QueryConstants.RANGE_OPENING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_OPENING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      } else {
        this.formStartTime = QueryConstants.RANGE_CLOSING_START_SECOND;
        this.formEndTime = QueryConstants.RANGE_CLOSING_END_SECOND;

        this.payload.startTime = this.formStartTime;
        this.payload.endTime = this.formEndTime;
      }
    });
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as GenAIYesNoQueryItemDto;
    let dtoType = this.node.categoryItem.dataType;

    this.formOperator = this.payload.operator;
    this.formSideId = this.payload.sideId;

    this.queryForm.get('genAIPrompt').patchValue(this.payload.genAIPrompt);
    this.queryForm.get('response').patchValue(this.payload.response);
    this.queryForm.get('conversationSide').patchValue(this.payload.sideId);

    switch (dtoType) {
      case this.queryFieldDataType.GenAIYesNo:
        this.currentGenAiYesNoQueryTypeInternal = GenAiYesNoQueryType.Basic;
        this.changeQueryType(GenAiYesNoQueryType.Basic, true);
        break;
      case this.queryFieldDataType.GenAIYesNoWithRange:
        this.currentGenAiYesNoQueryTypeInternal = GenAiYesNoQueryType.Interval;

        if (
          this.payload.startTime === QueryConstants.RANGE_OPENING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_OPENING_END_SECOND
        ) {
          this.currentGenAiYesNoQueryIntervalTypeInternal = GenAiYesNoQueryIntervalType.Opening;
          this.queryForm.get('intervalType').patchValue(GenAiYesNoQueryIntervalType.Opening);
        } else if (
          this.payload.startTime === QueryConstants.RANGE_CLOSING_START_SECOND &&
          this.payload.endTime === QueryConstants.RANGE_CLOSING_END_SECOND
        ) {
          this.currentGenAiYesNoQueryIntervalTypeInternal = GenAiYesNoQueryIntervalType.Closing;
          this.queryForm.get('intervalType').patchValue(GenAiYesNoQueryIntervalType.Closing);
        }
        this.changeQueryType(GenAiYesNoQueryType.Interval, true);
        break;
    }

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      let conversationSide = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationSide = conversationSide.name;
      this.payload.sideCode = conversationSide.code;
    });
    this.updateInputPhrases();
  }

  onQueryTypeMenuItemClicked(queryType: GenAiYesNoQueryType) {
    this.changeQueryType(queryType, false);
  }

  getSiblingNodePhrases(): string[] {
    return this.queryBuilder.getSiblingNodePhrasesOfNode(this.node);
  }

  updateInputPhrases() {
    this.inputPhrases$.next(this.getSiblingNodePhrases());
  }

  private changeQueryType(queryType: GenAiYesNoQueryType, initial: boolean) {
    this.currentGenAiYesNoQueryTypeInternal = queryType;
    let sideCode = initial
      ? this.genericLookupService.findGenericLookupWithId(this.payload.sideId)?.code
      : this.payload.sideCode;
    switch (queryType) {
      case GenAiYesNoQueryType.Basic:
        let payloadSimple = new GenAIYesNoQueryItemDto();

        payloadSimple.categoryId = this.payload.categoryId;
        payloadSimple.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_GENAI_YESNO;
        payloadSimple.id = initial ? this.payload.id : 0;
        payloadSimple.internalId = this.payload.internalId;
        payloadSimple.operator = this.payload.operator;
        payloadSimple.parentInternalId = this.payload.parentInternalId;
        payloadSimple.queryId = this.payload.queryId;

        payloadSimple.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadSimple.sideCode = sideCode;
        payloadSimple.field = this.payload.field;
        payloadSimple.genAIPromptId = this.payload.genAIPromptId;
        payloadSimple.genAIPrompt = this.payload.genAIPrompt;
        payloadSimple.response = this.payload.response;

        this.payload = payloadSimple;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.GenAIYesNo;
        break;
      case GenAiYesNoQueryType.Interval:
        if (initial == false) {
          this.queryForm.get('intervalType').patchValue(GenAiYesNoQueryIntervalType.Opening);
        }
        let payloadInterval = new GenAIYesNoWithRangeQueryItemDto();

        payloadInterval.categoryId = this.payload.categoryId;
        payloadInterval.categoryItemId = QueryConstants.CATEGORY_ITEM_ID_GENAI_YESNO_RANGE;
        payloadInterval.id = initial ? this.payload.id : 0;
        payloadInterval.internalId = this.payload.internalId;
        payloadInterval.operator = this.payload.operator;
        payloadInterval.parentInternalId = this.payload.parentInternalId;
        payloadInterval.queryId = this.payload.queryId;

        payloadInterval.sideId = initial ? this.payload.sideId : this.formSideId;
        payloadInterval.sideCode = sideCode;

        payloadInterval.startTime = initial ? this.payload.startTime : this.formStartTime;
        payloadInterval.endTime = initial ? this.payload.endTime : this.formEndTime;
        payloadInterval.rangeUnitId = this.formRangeUnitId;
        payloadInterval.field = this.payload.field;
        payloadInterval.genAIPromptId = this.payload.genAIPromptId;
        payloadInterval.genAIPrompt = this.payload.genAIPrompt;
        payloadInterval.response = this.payload.response;

        this.payload = payloadInterval;
        this.node.categoryItem.payload = this.payload;
        this.node.categoryItem.dataType = this.queryFieldDataType.GenAIYesNoWithRange;
        break;
    }

    if (!initial) {
      this.node.isDirty = true;
    }
  }

  getOpeningIntervalTooltip(): string {
    let tooltip = '';
    if (
      this.payload.sideId === ConversationSide.agent ||
      !this.seperateIntervalValueForChannelsEnabled
    ) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
    } else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryFirstNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryFirstNWords);
    } else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideFirstQueryNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryFirstNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryFirstNWords);
    }
    return tooltip;
  }

  getClosingIntervalTooltip(): string {
    let tooltip = '';
    if (
      this.payload.sideId === ConversationSide.agent ||
      !this.seperateIntervalValueForChannelsEnabled
    ) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
    } else if (this.payload.sideId === ConversationSide.customer) {
      tooltip = this.localizationService.instant('Query::IntervalQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalCustomerQueryLastNWords);
    } else {
      tooltip = this.localizationService.instant('Query::IntervalAnySideQueryLastNWords');
      tooltip = tooltip.replace('{0}', this.intervalAgentQueryLastNWords);
      tooltip = tooltip.replace('{1}', this.intervalCustomerQueryLastNWords);
    }
    return tooltip;
  }

  checkAndExpandPromptInputIfNeeded(input) {
    if (input.inputField.nativeElement.offsetWidth < input.inputField.nativeElement.scrollWidth) {
      this.queryForm.get('genAIPrompt').patchValue(input.text);
      this.expanded = true;
    }
  }

  test() {
    var queryItem: QueryItemDto = {
      dataType: this.node.categoryItem.dataType,
      serializedPayload: JSON.stringify(this.payload),
      internalId: 0,
    };

    var request: GenAIQueryItemTestRequestDto = {
      queryItem: queryItem,
      exceptList: this.exceptList,
    };

    this.testing = true;
    this.queryService.getGenAIQueryItemTestResponse(request).subscribe({
      next: response => {
        response.forEach(r => {
          this.exceptList.push(r.conversationId);
          this.data.unshift(r);
        });
        this.dataSource.connect().next(this.data);
      },
      error: () => {
        this.testing = false;
      },
      complete: () => {
        this.testing = false;
      },
    });
  }

  toggleTestResponsePopover(popover: NgbPopover, event: any): boolean {
    this.popover = popover;

    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
      this.dataSource.connect().next(this.data);
    }

    event.stopPropagation();
    return false;
  }
}
