import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { CategoryType } from 'src/core/models/enum/category-type.enum';
import { CategoryDto } from 'src/core/models/query/category.dto';
import { QueryDto } from 'src/core/models/query/query.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { CrudService } from 'src/core/services/crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private service: CrudService, private operators: Operators) {}

  getUserDefinedCategories(
    conversationType: number | null,
    searchTerm: string,
    filtersParams: FilterItemDto[],
    pageIndex: number,
    itemsPerPage: number
  ): Observable<ListResponseDto<QueryDto>> {
    let filters = ObjectHelper.deepCopy(filtersParams);
    filters = Array.isArray(filters) ? filters : [];

    filters.push({
      field: 'isActive',
      value: true,
      operator: this.operators.Equals,
    });

    if (searchTerm && searchTerm.length > 0) {
      filters.push({
        field: 'quickSearchTerm',
        value: searchTerm,
        operator: this.operators.Contains,
      });
    }

    if (conversationType !== null) {
      filters.push({
        field: 'conversationType',
        value: conversationType,
        operator: this.operators.Equals,
      });
    }

    return this.service.get<QueryDto>(QueryDto, {
      skipCount: pageIndex * itemsPerPage,
      maxResultCount: itemsPerPage,
      filters: filters,
      sorters: [],
    });
  }

  getCategories(
    conversationType: number | null,
    searchTerm: string,
    filtersParams: FilterItemDto[],
    pageIndex: number,
    itemsPerPage: number
  ): Observable<ListResponseDto<CategoryDto>> {
    let filters = ObjectHelper.deepCopy(filtersParams);
    filters = Array.isArray(filters) ? filters : [];

    filters.push({
      field: 'isActive',
      value: true,
      operator: this.operators.Equals,
    });

    if (searchTerm && searchTerm.length > 0) {
      filters.push({
        field: 'quickSearchTerm',
        value: searchTerm,
        operator: this.operators.Contains,
      });
    }

    if (conversationType !== null) {
      filters.push({
        field: 'conversationType',
        value: conversationType,
        operator: this.operators.Equals,
      });
    }

    return this.service.get<CategoryDto>(CategoryDto, {
      skipCount: pageIndex * itemsPerPage,
      maxResultCount: itemsPerPage,
      filters: filters,
      sorters: [],
    });
  }

  get(
    categoryType: CategoryType | null,
    conversationType: number | null,
    searchTerm: string,
    pageIndex: number,
    itemsPerPage: number
  ): Observable<ListResponseDto<CategoryDto>> {
    const filters: FilterItemDto[] = [
      {
        field: 'isActive',
        value: true,
        operator: this.operators.Equals,
      },
    ];

    if (searchTerm && searchTerm.length > 0) {
      filters.push({
        field: 'quickSearchTerm',
        value: searchTerm,
        operator: this.operators.Contains,
      });
    }

    if (categoryType !== null) {
      filters.push({
        field: 'categoryType',
        value: categoryType,
        operator: this.operators.Equals,
      });
    }

    if (conversationType !== null) {
      filters.push({
        field: 'conversationType',
        value: conversationType,
        operator: this.operators.Equals,
      });
    }

    return this.service.get<CategoryDto>(CategoryDto, {
      skipCount: pageIndex * itemsPerPage,
      maxResultCount: itemsPerPage,
      filters: filters,
      sorters: [],
    });
  }
}
