<div [formGroup]="form">
  <div class="form-group pt-3">
    <h4>{{ 'Conversation::AutomaticSummarization:SettingsTitle' | abpLocalization }}</h4>
  </div>
  <div class="form-group pt-1">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="automaticSummarizationEnabled"
        formControlName="automaticSummarizationEnabled" />
      <label class="form-check-label" for="automaticSummarizationEnabled">
        {{ 'Conversation::AutomaticSummarization:EnableAutomaticSummarization' | abpLocalization }}
      </label>
    </div>
  </div>
  <div class="form-group pt-2" *ngIf="form.get('automaticSummarizationEnabled').value">
    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="automaticSummarizationFilterConversations"
        type="radio"
        value="false"
        name="automaticSummarizationFilterConversations"
        id="automaticSummarizationFilterConversations-false" />
      <label class="form-check-label" for="automaticSummarizationFilterConversations-false">
        {{ 'Conversation::AutomaticSummarization:SummarizeAllConversation' | abpLocalization }}
      </label>
    </div>
  </div>
  <div class="form-group pt-2" *ngIf="form.get('automaticSummarizationEnabled').value">
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="automaticSummarizationFilterConversations"
        formControlName="automaticSummarizationFilterConversations"
        id="automaticSummarizationFilterConversations-true"
        value="true"
        checked />
      <label class="form-check-label" for="automaticSummarizationFilterConversations-true">
        {{ 'Conversation::AutomaticSummarization:FilterConversationByCategory' | abpLocalization }}
      </label>
    </div>
  </div>
  <div
    class="form-group pt-2"
    *ngIf="
      form.get('automaticSummarizationEnabled').value &&
      form.get('automaticSummarizationFilterConversations').value === 'true'
    ">
    <label for="automaticSummarizationCategories" class="form-label">
      {{ 'CA::Queries' | abpLocalization }}
    </label>
    <ca-category-selector
      [multiple]="true"
      [closeOnSelect]="false"
      placeholder="{{ '::PleaseSelectWithThreeDots' | abpLocalization }}"
      id="automaticSummarizationCategories"
      formControlName="automaticSummarizationCategories"></ca-category-selector>
  </div>
  <div class="form-group pt-2" *ngIf="form.get('automaticSummarizationEnabled').value">
    <label for="automaticSummarizationDailyConversationLimit" class="form-label">
      {{
        'Conversation::AutomaticSummarization:DailySummarizedConversationLimit' | abpLocalization
      }}
    </label>
    <input
      type="number"
      class="form-control form-control-sm"
      id="automaticSummarizationDailyConversationLimit"
      formControlName="automaticSummarizationDailyConversationLimit" />
    <small class="form-text text-muted ms-1">
      0 = {{ 'Conversation::AutomaticSummarization:Unlimited' | abpLocalization }}
    </small>
  </div>
</div>
