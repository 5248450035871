<ng-template #blockTemplate>
  <div class="d-flex justify-content-center align-items-center block-ui-template">
    <div class="spinner-border" role="status">
      <span class="sr-only">...</span>
    </div>
  </div>
</ng-template>

<ng-template #userItemTemplate let-item="item">
  <div class="mt-auto mb-auto truncate-text" [ngbTooltip]="item" showTooltipIfTruncated>
    {{ item }}
  </div>
</ng-template>

<div *blockUI="'conversation-table-block'; template: blockTemplate">
  <table
    mat-table
    matSort
    (matSortChange)="sortData($event)"
    [matSortDirection]="sortDirection$ | async"
    [matSortActive]="sortField$ | async"
    [matSortDisableClear]="true"
    [dataSource]="dataSource"
    aria-describedby="conversation-table">
    <ng-container *ngFor="let gridColumn of gridColumns">
      <ng-template #columnHeaderTooltipTemplate>
        <div [ngClass]="gridColumn.tooltip ? 'fw-bold' : ''">{{ gridColumn.header }}</div>
        <div *ngIf="gridColumn.tooltip">{{ gridColumn.tooltip }}</div>
      </ng-template>
      <ng-container
        *ngIf="
          gridColumn.columnName !== 'conversationId' &&
          gridColumn.columnName !== 'icons' &&
          gridColumn.columnName !== 'caller' &&
          gridColumn.columnName !== 'called' &&
          gridColumn.columnName !== 'userName'
        "
        matColumnDef="{{ gridColumn.columnName }}">
        <ng-container *ngIf="gridColumn.sortable === true">
          <th
            mat-header-cell
            [mat-sort-header]="gridColumn.sorterFieldName"
            *matHeaderCellDef
            container="body"
            [ngbTooltip]="columnHeaderTooltipTemplate"
            [style.width]="gridColumn.width"
            [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width"
            [ngClass]="gridColumn.headerClass"
            scope="col">
            {{ gridColumn.hideHeader ? '' : gridColumn.header }}
            <ca-column-resizer
              [gridId]="gridId"
              [columnId]="gridColumn.columnName"></ca-column-resizer>
          </th>
        </ng-container>
        <ng-container *ngIf="gridColumn.sortable !== true">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngbTooltip]="columnHeaderTooltipTemplate"
            [style.width]="gridColumn.width"
            [style.minWidth]="gridColumn.width"
            [style.maxWidth]="gridColumn.width"
            [ngClass]="gridColumn.headerClass"
            container="body"
            scope="col">
            {{ gridColumn.hideHeader ? '' : gridColumn.header }}
            <ca-column-resizer
              [gridId]="gridId"
              [columnId]="gridColumn.columnName"></ca-column-resizer>
          </th>
        </ng-container>
        <td
          mat-cell
          [ngClass]="gridColumn.cellClass"
          *matCellDef="let conversation"
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
          [ngClass]="gridColumn.cellClass"
          class="truncate-text"
          container="body"
          [ngbTooltip]="conversation | objectparser : gridColumn"
          showTooltipIfTruncated>
          {{ conversation | objectparser : gridColumn }}
        </td>
      </ng-container>
      <ng-container
        *ngIf="gridColumn.columnName === 'conversationId'"
        matColumnDef="{{ gridColumn.columnName }}"
        [sticky]="gridColumn.sticky">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
          scope="col"
          [ngClass]="gridColumn.headerClass">
          {{ gridColumn.hideHeader ? '' : gridColumn.header }}
          <ca-column-resizer
            [gridId]="gridId"
            [columnId]="gridColumn.columnName"></ca-column-resizer>
        </th>
        <td mat-cell [ngClass]="gridColumn.cellClass" *matCellDef="let conversation">
          <div class="d-flex">
            <button
              *ngIf="
                conversation.typeId != conversationType.chat &&
                conversation.typeId != conversationType.email &&
                (playingConversationId != conversation.id ||
                  (playingConversationId == conversation.id && !audioPlaying && !audioLoading))
              "
              type="button"
              class="btn btn-sm ca-btn ca-btn-outline ca-btn-player-controller toolbar-button"
              (click)="onPlayButtonClick(conversation)">
              <span class="fa-stack fa-1x original">
                <i class="far fa-circle fa-stack-2x"></i>
                <i class="fas fa-play fa-stack-1x"></i>
              </span>
              <span class="fa-stack fa-1x hover">
                <i class="fas fa-circle fa-stack-2x outer"></i>
                <i class="fas fa-play fa-stack-1x inner"></i>
              </span>
            </button>
            <button
              *ngIf="
                conversation.typeId != conversationType.chat &&
                conversation.typeId != conversationType.email &&
                playingConversationId == conversation.id &&
                audioPlaying
              "
              type="button"
              class="btn btn-sm ca-btn ca-btn-outline ca-btn-player-controller toolbar-button"
              (click)="onPauseButtonClick()">
              <span class="fa-stack fa-1x original">
                <i class="far fa-circle fa-stack-2x"></i>
                <i class="fas fa-pause fa-stack-1x"></i>
              </span>
              <span class="fa-stack fa-1x hover">
                <i class="fas fa-circle fa-stack-2x outer"></i>
                <i class="fas fa-pause fa-stack-1x inner"></i>
              </span>
            </button>
            <button
              *ngIf="
                conversation.typeId != conversationType.chat &&
                conversation.typeId != conversationType.email &&
                playingConversationId == conversation.id &&
                audioLoading
              "
              type="button"
              class="btn btn-sm ca-btn ca-btn-outline ca-btn-player-controller toolbar-button">
              <span class="fa-stack fa-1x">
                <i class="fa-duotone fa-spinner-third fa-spin fa-2x"></i>
              </span>
            </button>
            <ca-clickable-link
              class="d-flex align-items-center w-100"
              [cls]="'truncate w-100'"
              [innerHtml]="conversation | objectparser : gridColumn"
              [targetLink]="['/conversation', 'v2', conversation.id]"
              [params]="getQueryParams()"
              [state]="getState()"></ca-clickable-link>
          </div>
        </td>
      </ng-container>
      <ng-container
        *ngIf="gridColumn.columnName === 'userName'"
        matColumnDef="{{ gridColumn.columnName }}"
        [sticky]="gridColumn.sticky">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngbTooltip]="columnHeaderTooltipTemplate"
          [disableTooltip]="gridColumn.hideHeader"
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
          container="body"
          scope="col"
          [ngClass]="gridColumn.headerClass">
          {{ gridColumn.hideHeader ? '' : gridColumn.header }}
          <ca-column-resizer
            [gridId]="gridId"
            [columnId]="gridColumn.columnName"></ca-column-resizer>
        </th>
        <td
          mat-cell
          [ngClass]="gridColumn.cellClass"
          *matCellDef="let conversation"
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
          container="body">
          <ca-user-identity
            [id]="conversation.userId"
            [name]="conversation.userName"
            [surname]="conversation.userSurname"
            [item]="conversation.userName"
            [itemTemplate]="userItemTemplate"></ca-user-identity>
        </td>
      </ng-container>
      <ng-container
        *ngIf="gridColumn.columnName === 'icons'"
        matColumnDef="{{ gridColumn.columnName }}">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.minWidth || gridColumn.width"
          [style.maxWidth]="gridColumn.maxWidth"
          scope="col">
          {{ gridColumn.hideHeader ? '' : gridColumn.header }}
          <ca-column-resizer
            [gridId]="gridId"
            [columnId]="gridColumn.columnName"></ca-column-resizer>
        </th>
        <td
          mat-cell
          *matCellDef="let conversation"
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width">
          <ca-conversation-icon-container
            [conversation]="conversation"
            [showConversationType]="(activeList$ | async) === listType.mixed"
            [sentimentFeatureEnabled]="sentimentFeatureEnabled"></ca-conversation-icon-container>
        </td>
      </ng-container>
      <ng-container
        *ngIf="gridColumn.columnName === 'caller' || gridColumn.columnName === 'called'"
        matColumnDef="{{ gridColumn.columnName }}">
        >
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
          container="body"
          scope="col"
          [ngClass]="gridColumn.headerClass">
          {{ gridColumn.hideHeader ? '' : gridColumn.header }}
          <ca-column-resizer
            [gridId]="gridId"
            [columnId]="gridColumn.columnName"></ca-column-resizer>
        </th>
        <td
          mat-cell
          [ngClass]="gridColumn.cellClass"
          *matCellDef="let conversation"
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
          [ngClass]="gridColumn.cellClass"
          class="truncate-text"
          container="body"
          [ngbTooltip]="conversation | objectparser : gridColumn"
          showTooltipIfTruncated>
          <div
            [cdkCopyToClipboard]="conversation | objectparser : gridColumn"
            (click)="copyToClipboard()"
            class="conversation-copy-to-clipboard"
            title="{{ 'Conversation::CopytoClipboard' | abpLocalization }}">
            {{ conversation | objectparser : gridColumn }}
          </div>
        </td>
      </ng-container>
    </ng-container>
    <!-- Info column -->
    <ng-container matColumnDef="info">
      <td
        class="pl-0 text-start"
        mat-footer-cell
        *matFooterCellDef
        [attr.colspan]="displayedColumns.length">
        <span>
          <mat-paginator
            (page)="pageChanged($event)"
            [pageIndex]="currentPage"
            [length]="totalCount"
            [pageSize]="25"
            class="paginator d-flex justify-content-start"
            hidePageSize
            showFirstLastButtons></mat-paginator>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      [ngClass]="{
        'conversation-row-{{ myRowData.id }}': myRowData.id !== playingConversationId,
        'conversation-row-{{ myRowData.id }} selected': myRowData.id === playingConversationId
      }"
      (dblclick)="openFullscreen(myRowData.id)"
      *matRowDef="let myRowData; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="['info']; sticky: true"></tr>
    <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
      <td class="mat-cell" [colSpan]="displayedColumns.length">
        {{ 'CA::NoDataFound' | abpLocalization }}
      </td>
    </tr>
  </table>
</div>
