import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NamedEntityDto } from 'src/core/models/conversation/named-entity-recognition/named-entity.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';

@Injectable({
    providedIn: 'root',
})
export class NamedEntitySettingsService {
    constructor(private config: ConfigStateService, private http: HttpClient) { }

    getNEREntities(filters: FilterItemDto[], sorters: SorterItemDto[]): Observable<ListResponseDto<NamedEntityDto>> {
        const url = this.getApiUrl() + '/ner';
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filters));
        params = params.append('sorters', JSON.stringify(sorters));
        params = params.append('skipCount', JSON.stringify(0));
        params = params.append('maxResultCount', JSON.stringify(9999));

        return this.http.get(url, { params }) as Observable<ListResponseDto<NamedEntityDto>>;
    }

    updateNEREnability(definitionIds: number[]) {
        const url = this.getApiUrl() + '/update-ner-enability';
        return this.http.put(url, definitionIds);
    }

    getRedactionEntities(filters: FilterItemDto[], sorters: SorterItemDto[]): Observable<ListResponseDto<NamedEntityDto>> {
        const url = this.getApiUrl() + '/redaction';
        let params = new HttpParams();
        params = params.append('filters', JSON.stringify(filters));
        params = params.append('sorters', JSON.stringify(sorters));
        params = params.append('skipCount', JSON.stringify(0));
        params = params.append('maxResultCount', JSON.stringify(9999));

        return this.http.get(url, { params }) as Observable<ListResponseDto<NamedEntityDto>>;
    }

    updateRedactionEnability(entities: NamedEntityDto[]) {
        const url = this.getApiUrl() + '/update-redaction-enability';
        return this.http.put(url, entities);
    }

    private getApiUrl(): string {
        const apiBase = environment.apis.default.url;

        return apiBase.length > 0 ? apiBase + '/' + NamedEntityDto.apiUrl : NamedEntityDto.apiUrl;
    }
}
