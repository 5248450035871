import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.NamedEntityType'
})
export class ConversationNamedEntityType {
    @GenericLookup({ code: 'Conversation.NamedEntityType.NER' })
    static NER: number;

    @GenericLookup({ code: 'Conversation.NamedEntityType.Redaction' })
    static redaction: number;
}
