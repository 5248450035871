import { FeatureService } from './../core/services/feature/feature.service';
import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { SpeechRecognitionSettingsComponent } from './administration/settings/speech-recognition-settings/speech-recognition-settings.component';
import { StateVersionService } from 'src/core/services/state/state-version.service';
import {
  ReplaceableComponentsService,
  EnvironmentService,
  ConfigStateService,
  LocalizationService,
  PermissionService,
} from '@abp/ng.core';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { UserMainComponent } from './replacement/user/user-main/user-main.component';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { IndexingSettingsComponent } from './administration/settings/indexing-settings/indexing-settings.component';
import { GlobalSettingsComponent } from './administration/settings/global-settings/global-settings.component';
import { LicensingSettingsComponent } from './administration/settings/licensing-settings/licensing-settings.component';
import { AnalysisSettingsComponent } from './administration/settings/analysis-settings/analysis-settings.component';
import { CorporateIdentitySettingsComponent } from './administration/settings/corporate-identity-settings/corporate-identity-settings.component';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { InitializeUserState } from 'src/core/actions/generic/user-state.actions';
import { Select, Store, UpdateState } from '@ngxs/store';
import { CanDeactivateState } from 'src/core/guards/can-deactivate.guard';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, take, tap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { NavMenuItemService } from 'src/core/services/nav-menu/nav-menu-item.service';
import { QualityManagementSettingsComponent } from './administration/settings/quality-management-settings/quality-management-settings.component';
import { CorporateIdentitySettingsService } from 'src/core/services/settings/corporate-identity-settings.service';
import { TimeZoneHelper } from 'src/core/services/helper/timezone-helper';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { FooterComponent } from './footer/footer/footer.component';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ExternalLoginSettingsComponent } from './administration/settings/external-login-settings/external-login-settings.component';
import { ScopeService } from 'src/core/services/administration/scope.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgbConfig } from '@ng-bootstrap/ng-bootstrap';
import { eUserMenuItems } from '@abp/ng.theme.basic';
import { NavItemsService, UserMenuService } from '@abp/ng.theme.shared';
import { NotificationIconComponent } from 'src/ca-shared/nav-items/notification-icon/notification-icon.component';
import { ResetUserSettingsComponent } from 'src/ca-shared/nav-items/reset-user-settings/reset-user-settings.component';
import { AboutComponent } from 'src/ca-shared/nav-items/about/about.component';
import { CA_NAVIGATE_TO_MANAGE_PROFILE } from 'src/core/constants/ca-manage-profile.token';
import { CA_NAVIGATE_TO_SECURITY_LOGS } from 'src/core/constants/ca-security-logs.token';
import { LanguagesComponent } from 'src/ca-shared/nav-items/languages/languages.component';
import {
  PremiumReportingAddMenuService,
  PremiumReportingConfigService,
  PremiumReportingMenuService,
  SettingsComponent,
} from '@sestek/premium-reporting/config';
import { VideoPlayerIconComponent } from 'src/ca-shared/nav-items/video-player-icon/video-player-icon.component';
import { AutoDeletionSettingsComponent } from './administration/settings/auto-deletion-settings/auto-deletion-settings.component';
import { TextCorrectionSettingsComponent } from './administration/settings/text-correction-settings/text-correction-settings.component';
import { NotificationState } from 'src/core/states/notification/notification.state';
import { Observable } from 'rxjs';
import { NotificationCountSummaryDto } from 'src/core/models/notification/notification-count-summary.dto';
import { CallRecorderSettingsComponent } from './administration/settings/call-recorder-settings/call-recorder-settings.component';
import { ClearCacheComponent } from 'src/ca-shared/nav-items/clear-cache/clear-cache.component';
import { ZendeskApplicationSettingsComponent } from './administration/settings/zendesk-application-settings/zendesk-application-settings.component';
import { ZoomMeetingApplicationSettingsComponent } from './administration/settings/zoom-meeting-application-settings/zoom-meeting-application-settings.component';
import { ZoomContactCenterApplicationSettingsComponent } from './administration/settings/zoom-contactcenter-application-settings/zoom-contactcenter-application-settings.component';
import { MatIconRegistry } from '@angular/material/icon';
import { SettingService } from 'src/core/services/settings/setting.service';
import { RoleHelper } from 'src/core/helpers/role-helper';
import { IntegrationSettingsComponent } from './administration/settings/integration-settings/integration-settings.component';
import { OpenAiSettingsComponent } from './administration/settings/open-ai-settings/open-ai-settings.component';
import { CategorySettingsComponent } from './administration/settings/category-settings/category-settings.component';
import { ScreenRecorderSettingsComponent } from './administration/settings/screen-recorder-settings/screen-recorder-settings.component';
import { NamedEntityRecognitionSettingsComponent } from './administration/settings/named-entity-recognition-settings/named-entity-recognition-settings.component';
import { UserLoginDateChange } from 'src/core/actions/administration/user-login.action';
import { RedactionSettingsComponent } from './administration/settings/redaction-settings/redaction-settings.component';
import { RealTimeSettingsMainComponent } from './administration/settings/real-time-settings-main/real-time-settings-main.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  titleDictionary = new Map<string, string>([
    ['languages', 'LanguageManagement::Languages'],
    ['texts', 'LanguageManagement::LanguageTexts'],
    ['organization-units', 'AbpIdentity::OrganizationUnits'],
    ['roles', 'AbpIdentity::Roles'],
    ['users', 'AbpIdentity::Users'],
    ['identity/user-groups', 'UserGroup::Menu:UserGroups'],
    ['security-logs', 'AbpIdentity::SecurityLogs'],
    ['list', 'PremiumReporting::Menu:PremiumReportsList'],
    ['view', 'PremiumReporting::Menu:PremiumReportsView'],
    ['tenants', 'Saas::Tenants'],
    ['editions', 'Saas::Editions'],
  ]);
  private currentTitle: string;
  private notificationTitleInterval: any;
  private notificationCount: number = 0;
  private notificationTitleState: boolean = true;
  idParam: number = null;
  isSuperAdminUser: boolean = false;

  @Select(NotificationState.getCountSummary)
  notificationCountSummary$: Observable<NotificationCountSummaryDto>;

  constructor(
    private stateVersionService: StateVersionService,
    private settingTabs: SettingTabsService,
    private replaceableComponentsService: ReplaceableComponentsService,
    private configurationSettingsService: ConfigurationSettingsService,
    private configStateService: ConfigStateService,
    private store: Store,
    private featureService: FeatureService,
    private settingService: SettingService,
    private scopeService: ScopeService,
    readonly router: Router,
    private oAuthService: OAuthService,
    private menuItemService: NavMenuItemService,
    private timeZoneHelper: TimeZoneHelper,
    private corporateIdentitySettingsService: CorporateIdentitySettingsService,
    private environment: EnvironmentService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private localizationService: LocalizationService,
    private ngbConfig: NgbConfig,
    private navItemsService: NavItemsService,
    private userMenuService: UserMenuService,
    private injector: Injector,
    private premiumReportingConfigService: PremiumReportingConfigService,
    private premiumReportingMenuService: PremiumReportingMenuService,
    private premiumReportingAddMenuService: PremiumReportingAddMenuService,
    private permissionService: PermissionService,
    private matIconRegistry: MatIconRegistry,
    private roleHelper: RoleHelper,
    private domSanitizer: DomSanitizer
  ) {
    const navigateToManageProfile = this.injector.get(CA_NAVIGATE_TO_MANAGE_PROFILE);
    const navigateToSecurityLogs = this.injector.get(CA_NAVIGATE_TO_SECURITY_LOGS);

    this.configStateService.getDeep$('currentUser.id').subscribe(user => {
      if (user != null) {
        const actions = new UpdateState();
        this.store.dispatch(actions);
      }
    });

    let tenantId = this.configStateService.getDeep(['currentUser', 'tenantId']);
    let isHost = tenantId == null;
    this.isSuperAdminUser = this.roleHelper.isSuperAdminUser();

    this.ngbConfig.animation = false;

    this.applyCorporateIdentitySettings();
    this.registerCustomIcons();

    this.oAuthService.events.pipe(filter(event => event?.type === 'logout')).subscribe(event => {
      CanDeactivateState.hasLogoutBeenInitiated = true;
    });

    this.featureService.checkRoutesForFeatures();
    this.settingService.checkRoutesForSettings();
    this.scopeService.checkRoutesForScopes();

    // if the user clicks the back button, ask the CanDeactivateGuard to defend against this.
    window.onpopstate = () => (CanDeactivateState.defendAgainstBrowserBackButton = true);

    // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
    router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        tap(() => (CanDeactivateState.defendAgainstBrowserBackButton = false))
      )
      .subscribe(e => {
        const navigation = this.router.getCurrentNavigation();

        if (navigation.extras.state && navigation.extras.state.settings) {
          const tabId = navigation.extras.queryParams.tabId;

          setTimeout(() => {
            const tab = document.getElementById(tabId);

            if (tab) {
              tab.click();
            }
          }, 500);
        }
      });

    Date.prototype.toJSON = function () {
      var copiedDate = new Date(this.getTime());
      const minutesDiff =
        this.getMinutes() -
        (timeZoneHelper.GetSystemTimeZoneOffsetInMinutes() + this.getTimezoneOffset());
      copiedDate.setMinutes(minutesDiff);
      var result = copiedDate.toISOString();
      return result;
    };

    this.notificationService.notificationReceived.subscribe(() => {
      this.notificationCountSummary$.pipe(take(1)).subscribe(n => {
        if (document.hidden) {
          this.notificationCount += 1;
          this.startNotificationTitleTimer();
        }
      });
    });
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.stopNotificationTitleTimer();
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          this.idParam = null;

          while (child) {
            if (child.snapshot.params?.id > 0) {
              this.idParam = child.snapshot.params.id;
            }

            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              if (this.titleDictionary.has(child.snapshot.routeConfig.path)) {
                return this.titleDictionary.get(child.snapshot.routeConfig.path);
              }
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          let title = this.localizationService.instant(data);
          this.currentTitle = title;

          if (this.idParam > 0) {
            this.currentTitle = this.idParam + ' - ' + title;
          }

          this.titleService.setTitle(this.currentTitle);
        }
      });

    this.navItemsService.addItems([
      {
        id: 'VideoPlayerIcon',
        order: 1,
        component: VideoPlayerIconComponent,
      },
    ]);

    this.navItemsService.addItems([
      {
        id: 'NotificationIcon',
        order: 2,
        component: NotificationIconComponent,
      },
    ]);

    this.navItemsService.patchItem(eThemeLeptonComponents.Languages, {
      component: LanguagesComponent,
    });

    const userItems: any[] = [
      {
        id: 'ResetUserSettingsLink',
        order: 101,
        component: ResetUserSettingsComponent,
      },
      {
        id: 'AboutLink',
        order: 103,
        component: AboutComponent,
      },
    ];

    if (isHost) {
      userItems.push({
        id: 'ClearCacheLink',
        order: 102,
        component: ClearCacheComponent,
      });
    }

    this.userMenuService.addItems(userItems);

    this.userMenuService.patchItem(eUserMenuItems.Logout, {
      order: 104,
    });

    this.userMenuService.patchItem(eUserMenuItems.MyAccount, {
      action: () => navigateToManageProfile(),
    });

    this.userMenuService.patchItem('UserMenu.SecurityLogs', {
      action: () => navigateToSecurityLogs(),
    });

    this.userMenuService.removeItem('UserMenu.LinkedAccounts');
    var impersonateUserId = this.configStateService.getDeep('currentUser.impersonatorUserId');

    if (impersonateUserId) {
      this.userMenuService.removeItem(eUserMenuItems.MyAccount);
      this.userMenuService.removeItem('UserMenu.SecurityLogs');
    }

    this.initializeLogoutControl();
  }

  applyCorporateIdentitySettings() {
    this.corporateIdentitySettingsService.get().subscribe(result => {
      const logoFileBase64Uri = `data:${result.logoFileContentType};base64,${result.logoFileBytes}`;

      const environment = ObjectHelper.deepCopy(this.environment.getEnvironment());
      environment.application.name = result.title;
      environment.application.logoUrl = logoFileBase64Uri;
      this.environment.setState(environment);
      this.setLogo(logoFileBase64Uri);

      const faviconFileBase64Uri = `data:${result.faviconFileContentType};base64,${result.faviconFileBytes}`;
      this.setFavicon(faviconFileBase64Uri);
    });
  }

  setLogo(logoFileBase64Uri: string) {
    const logoUrl = `url("${logoFileBase64Uri}")`;
    document.documentElement.style.setProperty('--logo', logoUrl, 'important');
    document.documentElement.style.setProperty('--logo-reverse', logoUrl, 'important');
  }

  setFavicon(faviconFileBase64Uri: string) {
    const favIcon: HTMLLinkElement = document.querySelector('#appFavicon');
    favIcon.href = faviconFileBase64Uri;
  }

  ngOnInit() {
    this.checkInitialSetup();

    this.saveUserLoginState();

    this.replaceableComponentsService.add({
      component: UserMainComponent,
      key: 'Identity.UsersComponent',
    });

    this.replaceableComponentsService.add({
      component: FooterComponent,
      key: eThemeLeptonComponents.ApplicationLayoutFooter,
    });

    this.settingTabs.add([
      {
        name: 'PremiumReporting::Menu:PremiumReports',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: SettingsComponent,
      },
      {
        name: 'Settings::Indexing',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: IndexingSettingsComponent,
      },
      {
        name: 'Settings::ZoomMeetingApplication',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: ZoomMeetingApplicationSettingsComponent,
      },
      {
        name: 'Settings::ZoomContactCenterApplication',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: ZoomContactCenterApplicationSettingsComponent,
      },
      {
        name: 'Settings::ZendeskApplication',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: ZendeskApplicationSettingsComponent,
      },
      {
        name: 'Settings::Global',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: GlobalSettingsComponent,
      },
      {
        name: 'Settings::Analysis',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: AnalysisSettingsComponent,
      },
      {
        name: 'Settings::RealTime',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: RealTimeSettingsMainComponent,
      },
      {
        name: 'SpeechRecognition::SpeechRecognitionAgentTraining',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: SpeechRecognitionSettingsComponent,
      },
      {
        name: 'Settings::CategorizationSettings',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: CategorySettingsComponent,
      },
      {
        name: 'Settings::QualityManagement',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: QualityManagementSettingsComponent,
      },
      {
        name: 'Settings::CorporateIdentity',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: CorporateIdentitySettingsComponent,
      },
      {
        name: 'Settings::CallRecorder',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: CallRecorderSettingsComponent,
      },
      {
        name: 'Settings::Integration',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: IntegrationSettingsComponent,
      },
      {
        name: 'Settings::ExternalLogin',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: ExternalLoginSettingsComponent,
      },
      {
        name: 'Settings::AutoDeletionTitle',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: AutoDeletionSettingsComponent,
      },
      {
        name: 'Settings::TextCorrection.SettingsLabel',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: TextCorrectionSettingsComponent,
      },
      {
        name: 'Settings::Licensing',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: LicensingSettingsComponent,
      },
      {
        name: 'Settings::OpenAiSettings',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: OpenAiSettingsComponent,
      },
      {
        name: 'Settings::ScreenRecorder',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: ScreenRecorderSettingsComponent,
      },
      {
        name: 'Settings::NamedEntityRecognition',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: NamedEntityRecognitionSettingsComponent,
      },
      {
        name: 'Settings::Redaction',
        requiredPolicy: 'AbpIdentity.SettingManagement',
        component: RedactionSettingsComponent,
      },
    ]);

    if (this.settingTabs.find(x => x.name == 'AbpIdentity::Menu:IdentityManagement')) {
      this.settingTabs.patch('AbpIdentity::Menu:IdentityManagement', {
        name: 'AbpIdentity::Settings:IdentityManagement',
      });
    }

    if (!this.featureService.isEnabled(FeatureConstants.PremiumReporting)) {
      this.settingTabs.remove(['PremiumReporting::Menu:PremiumReports']);
    } else {
      this.settingTabs.patch('PremiumReporting::Menu:PremiumReports', null);
    }

    if (!this.featureService.isEnabled(FeatureConstants.Analytics)) {
      this.settingTabs.remove(['AITopic::AIGeneratedCategoriesSettings']);
      this.settingTabs.remove(['Settings::OpenAiSettings']);
      this.settingTabs.remove(['Settings::TextCorrection.SettingsLabel']);
      this.settingTabs.remove(['Settings::CategorizationSettings']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.GenerativeQA)) {
      this.settingTabs.remove(['Settings::GenerativeQA']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.Training)) {
      this.settingTabs.remove(['SpeechRecognition::SpeechRecognitionAgentTraining']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.RealTime)) {
      this.settingTabs.remove(['Settings::RealTime']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.QualityManagement)) {
      this.settingTabs.remove(['Settings::QualityManagement']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.ExternalLogin)) {
      this.settingTabs.remove(['Settings::ExternalLogin']);
    }

    if (
      this.featureService.isEnabled(FeatureConstants.CallRecorder) ||
      !this.permissionService.getGrantedPolicy('Conversation.CanManageRetention')
    ) {
      this.settingTabs.remove(['Settings::AutoDeletionTitle']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.CallRecorder)) {
      this.settingTabs.remove(['Settings::CallRecorder']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.ScreenRecorder)) {
      this.settingTabs.remove(['Settings::ScreenRecorder']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.ChatType)) {
      this.settingTabs.remove(['Settings::TextCorrection.SettingsLabel']);
    }

    if (!this.featureService.isEnabled(FeatureConstants.FeatureNamedEntityRecognition)) {
      this.settingTabs.remove(['Settings::NamedEntityRecognition']);
    }

    if (!this.isHostUser()) {
      this.settingTabs.remove(['Settings::Indexing']);
      this.settingTabs.remove(['Settings::ZoomMeetingApplication']);
      this.settingTabs.remove(['Settings::ZoomContactCenterApplication']);
      this.settingTabs.remove(['Settings::ZendeskApplication']);
    }

    if (this.isHostUser()) {
      this.settingTabs.remove(['Settings::Integration']);
    } else if (!this.checkIntegration()) {
      this.settingTabs.remove(['Settings::Integration']);
    }

    if (!this.isSuperAdminUser) {
      this.settingTabs.remove(['Settings::Licensing']);
      this.settingTabs.remove(['Settings::CorporateIdentity']);
      this.settingTabs.remove(['SpeechRecognition::SpeechRecognitionAgentTraining']);
    }

    const isPremiumReportingFeatureEnabled = this.configStateService.getFeature(
      'PremiumReportingGroup.PremiumReporting'
    );
    if (
      this.permissionService.getGrantedPolicy('PremiumReporting.PremiumReports') &&
      isPremiumReportingFeatureEnabled === 'true'
    ) {
      this.getPremiumReportingMenuList();
      this.premiumReportingMenuService.premiumReportingMenuChange.subscribe(() =>
        this.getPremiumReportingMenuList()
      );
    }

    this.menuItemService.overrideNavMenuItemIcons();
  }

  getPremiumReportingMenuList() {
    this.premiumReportingConfigService.get({ maxResultCount: 50 }).subscribe(res => {
      this.premiumReportingAddMenuService.addMenu(res.items);
      this.menuItemService.overridePremiumReportsNavMenuItemIcons();
    });
  }

  isHostUser(): boolean {
    let tenantId = this.configStateService.getDeep(['currentUser', 'tenantId']);
    return tenantId == null;
  }

  checkInitialSetup() {
    const tenantId = this.configStateService.getDeep('currentUser.tenantId');

    const isInitialSetupCompleted = this.configStateService.getSetting(
      'Global.IsInitialTenantSetupCompleted'
    );

    if (
      this.oAuthService.hasValidAccessToken() &&
      tenantId &&
      isInitialSetupCompleted === 'False'
    ) {
      this.router.navigate(['/initial-setup']);
    }
  }

  saveUserLoginState() {
    if (this.oAuthService.hasValidAccessToken()) {
      this.saveState();
    }
  }

  saveState() {
    const date = new Date();
    const userLoginAction = new UserLoginDateChange(date);
    this.store.dispatch(userLoginAction);
  }

  ngAfterViewInit() {}

  private loadCrazyEgg() {
    let node = document.createElement('script');
    node.src = '//script.crazyegg.com/pages/scripts/0120/5459.js';
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private startNotificationTitleTimer() {
    clearInterval(this.notificationTitleInterval);
    this.toggleNotificationTitle();
    this.notificationTitleInterval = setInterval(() => this.toggleNotificationTitle(), 3000);
  }

  private stopNotificationTitleTimer() {
    clearInterval(this.notificationTitleInterval);
    this.notificationTitleState = true;
    this.notificationCount = 0;
    this.titleService.setTitle(this.currentTitle);
  }

  private toggleNotificationTitle() {
    if (this.notificationTitleState) {
      this.titleService.setTitle(
        this.localizationService.instant('::TitleNotification', this.notificationCount.toString())
      );
    } else {
      this.titleService.setTitle(this.currentTitle);
    }

    this.notificationTitleState = !this.notificationTitleState;
  }

  private initializeLogoutControl() {
    this.oAuthService.events.subscribe(event => {
      if (event.type === 'logout' && this.oAuthService['persistRedirectUri'] === undefined) {
        this.oAuthService.redirectUri = window.location.origin;
      }
    });
  }

  private registerCustomIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'open-ai',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/open-ai.svg')
    );
  }

  private checkIntegration() {
    let amazonIntegration = this.featureService.isEnabled(FeatureConstants.AmazonIntegration);
    let amazonIntegrationOutOfMarketplace = this.featureService.isEnabled(
      FeatureConstants.AmazonIntegrationOutOfMarketplace
    );
    let aloTechIntegration = this.featureService.isEnabled(FeatureConstants.AloTechIntegration);
    let puzzelIntegration = this.featureService.isEnabled(FeatureConstants.PuzzelIntegration);
    let GenesysIntegration = this.featureService.isEnabled(FeatureConstants.GenesysIntegration);
    let GenesysIntegrationOutOfMarketplace = this.featureService.isEnabled(
      FeatureConstants.GenesysIntegrationOutOfMarketplace
    );
    let ZendeskIntegration = this.featureService.isEnabled(FeatureConstants.ZendeskIntegration);
    let ZoomMeetingIntegration = this.featureService.isEnabled(
      FeatureConstants.ZoomMeetingIntegration
    );
    let ZoomContactCenterIntegration = this.featureService.isEnabled(
      FeatureConstants.ZoomContactCenterIntegration
    );

    let twilioIntegration = this.featureService.isEnabled(FeatureConstants.TwilioIntegration);

    return (
      amazonIntegration ||
      amazonIntegrationOutOfMarketplace ||
      aloTechIntegration ||
      puzzelIntegration ||
      GenesysIntegration ||
      GenesysIntegrationOutOfMarketplace ||
      ZendeskIntegration ||
      ZoomContactCenterIntegration ||
      ZoomMeetingIntegration ||
      twilioIntegration
    );
  }
}
